
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import HomePage from "./pages/homePage/HomePage.jsx"
import Credentials from "./pages/credentialsPage/CredentialsPage.jsx"
import Board from "./pages/boardPage/BoardPage.jsx"
import Contact from "./pages/contactPage/ContactPage.jsx"
import Testing from "./pages/testingPolicy/testingPolicy.jsx"
import AboutPage from "./pages/aboutPage/About.jsx"
import { Box as Partners } from "./pages/partnersPage/partnersPage.jsx"
import AccommodationsPage from "./pages/testingPolicy/accommodationsPage.jsx"
import ExamScoringPage from "./pages/testingPolicy/examScoringPage.jsx"
import ExamContentPage from "./pages/testingPolicy/examContentPage.jsx"
import ExamFeedbackPage from "./pages/testingPolicy/examFeedbackPage.jsx"
import CandidateRetesting from "./pages/testingPolicy/candidateRetesting.jsx"
import CandidatePhotograph from "./pages/testingPolicy/candidatePhotograph.jsx"
import TestCenterPolicy from "./pages/testingPolicy/testCenterPolicy.jsx"
import OnlineExamPolicy from "./pages/testingPolicy/onlineExamPolicy.jsx"
import IdPolicy from "./pages/testingPolicy/idPolicy.jsx"
import TestingAgreement from "./pages/testingPolicy/testingAgreement.jsx"
import DeliveryOfExam from "./pages/testingPolicy/deliveryOfExam.jsx"
import PoliciesPage from "./pages/PoliciesPage/policiesPage.jsx"
import PolicyAppeal from "./pages/PoliciesPage/appealPolicy.jsx"
import OutSourcing from "./pages/PoliciesPage/outSourcing.jsx"
import InternalAudit from "./pages/PoliciesPage/internalAudit.jsx"
import InvalCert from "./pages/PoliciesPage/invalCert.jsx"
import CertUse from "./pages/PoliciesPage/certUse.jsx"
import ComplaintProcess from "./pages/PoliciesPage/complaintProcess.jsx"
import AdvisoryGroup from "./pages/PoliciesPage/advisoryGroup.jsx"
import Nondiscrimination from "./pages/PoliciesPage/nondiscrimination.jsx"
import DocumentControl from "./pages/PoliciesPage/documentControl.jsx"
import InternalPersonnel from "./pages/PoliciesPage/internalPersonnel.jsx"
import ManagementReview from "./pages/PoliciesPage/managementReview.jsx"
import PrivacyPolicy from "./pages/PoliciesPage/privacyPolicy.jsx"
import SpecialAccommodation from "./pages/PoliciesPage/specialAccommodations.jsx"
import TestingPolicy from "./pages/testingPolicy/testingPolicy.jsx"
import CodeOfEthics from "./pages/codeOfEthics/codeOfEthics.jsx"
import TestingOptions from "./pages/testingOptions/testingOptions.jsx"
import PolicyRenewalPage from "./pages/policyRenewalPage/policyRenwalPage.jsx"
import RetestingPolicy from "./pages/testingPolicy/retestingPage.jsx"
import JsExamDetailsPage from "./pages/jsExamDetailsPage/jsExamDetailsPage.jsx"
import MysqlExamDetailsPage from "./pages/mysqlExamDetailsPage/mysqlExamDetailsPage.jsx"
import ReactExamDetailsPage from "./pages/reactExamDetailsPage/ReactExamDetailPage.jsx"
import ReCertPolicy from "./pages/PoliciesPage/reCertPolicy.jsx"
import RefundPolicy from "./pages/testingPolicy/refundPolicy.jsx"
import SignInPage from "./pages/signInPage/signInPage.jsx"
import SignUpPage from "./pages/signUpPage/signUpPage.jsx"
import WOIABlog from "./pages/blogPage/woia.jsx"
import Blog from "./pages/blogPage/blogPage.jsx"

// import PaymentOption from "./components/paymentOption/paymentOption.jsx";
import "./App.css"
import HtmlCssExamDetailsPage from "./pages/htmlCSSExamDetailsPage/htmlCSSExamDetailsPage.jsx"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        {/* <Route path="/signin" element={<SignInPage />}></Route>
        <Route path="/signup" element={<SignUpPage />}></Route> */}
        <Route path="/credentials" element={<Credentials />}></Route>
        <Route path="/board" element={<Board />}></Route>
        <Route path="/blog" element={<Blog />}></Route>
        <Route
          path="/blog/Workforce-Innovation-Act"
          element={<WOIABlog />}
        ></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/testing" element={<Testing />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/testing-policies" element={<TestingPolicy />} />
        <Route
          path="/testing/accommodations"
          element={<AccommodationsPage />}
        />
        <Route path="/testing/exam-scoring" element={<ExamScoringPage />} />
        <Route path="/testing/exam-content" element={<ExamContentPage />} />
        <Route path="/testing/exam-feedback" element={<ExamFeedbackPage />} />
        <Route
          path="/testing/candidate-retesting"
          element={<CandidateRetesting />}
        />
        <Route
          path="/testing/candidate-photograph"
          element={<CandidatePhotograph />}
        />
        <Route
          path="/testing/test-center-policy"
          element={<TestCenterPolicy />}
        />
        <Route
          path="/testing/online-exam-policy"
          element={<OnlineExamPolicy />}
        />
        <Route path="/testing/id-policy" element={<IdPolicy />} />
        <Route
          path="/testing/testing-agreement"
          element={<TestingAgreement />}
        />
        <Route path="/testing/delivery-of-exam" element={<DeliveryOfExam />} />
        <Route path="/testing/retesting-policy" element={<RetestingPolicy />} />
        <Route path="/code-of-ethics" element={<CodeOfEthics />} />
        <Route path="/testing-options" element={<TestingOptions />} />
        <Route path="/policy-renewal-page" element={<PolicyRenewalPage />} />
        <Route path="/jsexam-details-page" element={<JsExamDetailsPage />} />

        <Route path="/reactexam-details-page" element={<ReactExamDetailsPage />} />
        <Route path="/mysqlexam-details-page" element={<MysqlExamDetailsPage />} />
        <Route path="/htmlcssexam-details-page" element={<HtmlCssExamDetailsPage />} />
        <Route path="/testing/refund-policy" element={<RefundPolicy />} />
        {/* <Route path="/payment-option" element={<PaymentOption />} /> */}

        {/* Policy page routes */}
        <Route path="/policies-page" element={<PoliciesPage />} />
        <Route
          path="/policy/appeal-process-policy"
          element={<PolicyAppeal />}
        />
        <Route
          path="/policy/outsourcing-and-collaborative-partnership-policy"
          element={<OutSourcing />}
        />
        <Route
          path="/policy/internal-audit-policy"
          element={<InternalAudit />}
        />
        <Route
          path="/policy/invalidating-a-certification-policy"
          element={<InvalCert />}
        />
        <Route
          path="/policy/candidate-certification-use-and-recertification-policy"
          element={<CertUse />}
        />
        <Route
          path="/policy/complaint-process-policy"
          element={<ComplaintProcess />}
        />
        <Route
          path="/policy/advisory-board-composition-and-responsibilities"
          element={<AdvisoryGroup />}
        />
        <Route
          path="/policy/nondiscrimination-policy"
          element={<Nondiscrimination />}
        />
        <Route
          path="/policy/document-control-policy"
          element={<DocumentControl />}
        />
        <Route
          path="/policy/internal-personnel-policy"
          element={<InternalPersonnel />}
        />
        <Route
          path="/policy/management-review-policy"
          element={<ManagementReview />}
        />
        <Route
          path="/policy/privacy-confidentiality-and-security-policy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="/special-accommodations"
          element={<SpecialAccommodation />}
        />
        <Route path="/policy/recert-policy" element={<ReCertPolicy />} />
      </Routes>
    </Router>
  )
}

export default App
